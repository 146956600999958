import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import PostList from '../components/Blog/PostList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { postsOnPage, categoryFilter, categories, site, wordpressWpSettings, yoast = [], siteSettings } = data
    const { wordpressUrl } = wordpressWpSettings
    const { showAuthor, customCss } = siteSettings.options
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle, date_format } = wordpressWpSettings
    const { name: category, slug, pathPrefix } = pageContext
    const title = `${category} Category`
    const thisCategory = categories.edges.filter(i => i.node.slug === slug)
    const featuredImage = thisCategory[0] && thisCategory[0].node.featured_media ? categories.edges[0].node.featured_media : ''

    return (
      <>
        <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} yoast={yoast}
          location={location}
        />
        <PageHeader headerTitle={title} headerBackgroundImage="" location={location} headerBackgroundImage={featuredImage} />
        <PostList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix={pathPrefix}
          showAuthor={showAuthor}
          dateFormat={date_format}
        />
      </>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
      date_format
    }
    siteSettings: wordpressAcfOptions {
      options {
        showAuthor
        customCss
      }
    },
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          path
          id
        }
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "dummy-post" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 670, quality: 70) {
                  ... GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          count
        }
      }
    }
  }
`
